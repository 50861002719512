import { hover } from '@testing-library/user-event/dist/hover'
import React, {useState} from 'react';
import Video from '../../videos/video.mp4';
import {Button, ButtonR} from '../ButtonElements';
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements'


//heroSection ist mein Video/Bild auf der Startseite
const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent>
            <HeroH1>Virtueller Fußballmanager</HeroH1>
            <HeroP>
                Registriere dich jetzt und zeige deinen Freunden, wer der beste Manager auf dem Platz ist.
            </HeroP>
            <HeroBtnWrapper>
                <ButtonR 
                to="/signup" onMouseEnter={onHover} onMouseLeave={onHover}
                smooth={true} 
                duration={500} 
                spy={true} 
                exact='true' 
                offset={-80}
                >
                Hier registrieren {hover ? <ArrowForward /> : <ArrowRight/>}
                </ButtonR>
            </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
  )
}

export default HeroSection