import React from 'react'
import SignIn from '../components/Signin';
import ScrollToTop from '../components/ScrollToTop';

const SigninPage = () => {
  return (

      <>
      <ScrollToTop />
      <SignIn></SignIn>
      </>
      
    
  );
};

export default SigninPage;