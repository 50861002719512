import React from 'react'
import { Container, FormWrap, //Icon, 
    FormButton, Text, Form, FormContent, 
    FormH1, FormInput, FormLabel, FormBg, VideoBg, FormH2} from './SigninElements'
import Video from '../../videos/video3.mp4';

const SignIn = () => {
  return (
    <>

    <Container>
        <FormBg>
            <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4'/>
        </FormBg>
        <FormWrap>
          <FormContent>
                <Form action="#">
                    <FormH1 to="/">SoccerCoach</FormH1>
                    <FormH2>In deinen Account einloggen</FormH2>
                    <FormLabel htmlFor='for'>E-Mail</FormLabel>
                    <FormInput type='email' required></FormInput>
                    <FormLabel htmlFor='for'>Passwort</FormLabel>
                    <FormInput type='password' required></FormInput>
                    <FormButton type='submit'>Einloggen</FormButton>
                    <Text>Passwort vergessen?</Text>
                </Form>
            </FormContent>
        </FormWrap>
    </Container>
    </>
  )
}

export default SignIn