import React from 'react'
import {FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll/modules'
import { FooterContainer, FooterWrap, FooterLinksContainer, 
    FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, 
    FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights,
    SocialIcons, SocialIconLink } from './FooterElements'


const Footer = () => {

    //Funktion: bei Klick auf Logo wird nach ganz oben gescrollt
    const toggleHome = () => {
        scroll.scrollToTop();
    }

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Über uns</FooterLinkTitle>
                            <FooterLink to="/">Referenzen</FooterLink>
                            <FooterLink to="/">Karriere</FooterLink>
                            <FooterLink to="/">Investoren</FooterLink>
                            <FooterLink to="/">Teilnahmebedingungen</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Kontaktiere uns</FooterLinkTitle>
                            <FooterLink to="/">Kontakt</FooterLink>
                            <FooterLink to="/">Support</FooterLink>
                            <FooterLink to="/">Sponsoren</FooterLink>
                            <FooterLink to="/">Teilnahmebedingungen</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Videos</FooterLinkTitle>
                            <FooterLink to="/">Referenzen</FooterLink>
                            <FooterLink to="/">Karriere</FooterLink>
                            <FooterLink to="/">Sponsoren</FooterLink>
                            <FooterLink to="/">Teilnahmebedingungen</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <FooterLink to="//www.instagram.com/">Instagram</FooterLink>
                            <FooterLink to="//www.facebook.com/">Facebook</FooterLink>
                            <FooterLink to="//www.youtube.com/">YouTube</FooterLink>
                            <FooterLink to="//www.twitter.com/">Twitter</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/" onClick={toggleHome}>
                        SoccerCoach
                    </SocialLogo>
                    <WebsiteRights>SoccerCoach © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="//www.facebook.com/" target="_blank" aria-label="Facebook">
                            <FaFacebook />
                        </SocialIconLink>
                        <SocialIconLink href="//www.instagram.com/" target="_blank" aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="//www.youtube.com/" target="_blank" aria-label="Youtube">
                            <FaYoutube />
                        </SocialIconLink>
                        <SocialIconLink href="//www.twitter.com/" target="_blank" aria-label="Twitter">
                            <FaTwitter />
                        </SocialIconLink>
                        <SocialIconLink href="//www.linkedin.com/" target="_blank" aria-label="Linkedin">
                            <FaLinkedin />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer