import React from 'react';
import Icon1 from '../../images/svg-4.svg';
import Icon2 from '../../images/svg-5.svg';
import Icon3 from '../../images/svg-6.svg';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './servicesElements';

const Services = () => {
  return (
    <>
    <ServicesContainer id="services">
        <ServicesH1>Unsere Leistungen</ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1} />
                <ServicesH2>Punkte in Echtzeit</ServicesH2>
                <ServicesP>Erhalte Punkte für deine Mannschaft in Echtzeit</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon2} />
                <ServicesH2>Verschiedene Geräte</ServicesH2>
                <ServicesP>Zugriff per Desktop, Smartphone und App</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon3} />
                <ServicesH2>Premium Vorteile</ServicesH2>
                <ServicesP>Möglichkeit Premium Vorteile freizuschalten</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
    </>
  )
}

export default Services