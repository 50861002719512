import React from 'react'
import { Container, FormWrap, //Icon, 
    FormButton, Text, Form, FormContent, 
    FormH1, FormInput, FormLabel, FormBg, VideoBg, FormH2, PictureBg} from './SignupElements'
import Video from '../../videos/video3.mp4';

const SignUp = () => {
  return (
    <>
    <Container>
        <FormBg>
            <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4'/>
        </FormBg>
        <FormWrap>
            <FormContent>
                <Form action="#">
                    <FormH1 to="/">SoccerCoach</FormH1>
                    <FormH2>Einen neuen Account erstellen</FormH2>
                    <FormLabel htmlFor='for'>Vorname</FormLabel>
                    <FormInput type='firstName' required></FormInput>
                    <FormLabel htmlFor='for'>Nachname</FormLabel>
                    <FormInput type='secondName' required></FormInput>
                    <FormLabel htmlFor='for'>E-Mail</FormLabel>
                    <FormInput type='email' required></FormInput>
                    <FormLabel htmlFor='for'>Passwort</FormLabel>
                    <FormInput type='password' required></FormInput>
                    <FormButton type='submit'>Registrieren</FormButton>
                </Form>
            </FormContent>
        </FormWrap>
    </Container>
    </>
  )
}

export default SignUp
