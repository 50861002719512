import React from 'react'
import SignUp from '../components/Signup';
import ScrollToTop from '../components/ScrollToTop';

const SignupPage = () => {
  return (

      <>
      <ScrollToTop />
      <SignUp></SignUp>
      </>
      
    
  );
};

export default SignupPage;