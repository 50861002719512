import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, 
    SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from './sidebarElements';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="about" onClick={toggle}>Über uns</SidebarLink>
                <SidebarLink to="discover" onClick={toggle}>Soccercoach entdecken</SidebarLink>
                <SidebarLink to="services" onClick={toggle}>Leistungen</SidebarLink>
                <SidebarLink to="signup" onClick={toggle}>Registrieren</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/signin">Anmelden</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar