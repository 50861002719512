export const homeObjOne = {

    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Über SoccerCoach',
    headLine: 'Stelle deine individuelle Mannschaft zusammen',
    description: 'Erhalte Punkte für den Spieltag und messe dich mit deinen Freunden, wenn es darum geht, wer der bessere Manager auf dem Platz ist',
    buttonLabel: 'Jetzt starten',
    imgStart: false,
    img: require('../../images/svg-1.svg').default,
    alt: 'Football Player',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {

    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Soccercoach entdecken',
    headLine: 'Erhalte Punkte für den aktuellen Spieltag',
    description: 'Erhalte Punkte in Echtzeit und messe dich mit deinen Freunden, wenn es darum geht, wer der bessere Manager auf dem Platz ist',
    buttonLabel: 'Mehr erfahren',
    imgStart: true,
    img: require('../../images/svg-2.svg').default,
    alt: 'Football Fans',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {

    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Hier Registrieren',
    headLine: 'Erstelle einen Account um die Bestenliste zu erobern',
    description: 'Jetzt einen Account erstellen und viele neue Vorteile genießen',
    buttonLabel: 'Leg los',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Party',
    dark: false,
    primary: false,
    darkText: true
};